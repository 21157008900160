import React, {useContext, useState} from 'react';

import { QuizContext } from '../../context/QuizContext';

import Aux from '../../hoc/Aux';
import Modal from '../UI/Modal/Modal';
import Button from '../UI/Button/button';

const HasRestrictions = props => {
  const [hasRestrictions, setHasRestrictions] = useState(null)

  const [showModal, setShowModal] = useState(true);

  const onHandleClick = option => {
    setHasRestrictions(option)
  };

  const onHandleModalClosed = () => {
    setShowModal(false)
    props.history.push("/")
  };

  const onContinueBtn = () => {
    hasRestrictions ? props.history.push('/restrictions') : props.history.push('/info');
  };

  const onBackBtn = () => {
    props.history.goBack();
  }

  return (
    <Aux>
      <Modal show={showModal} modalClosed={onHandleModalClosed}>
        <h3>Você possui alguma restrição alimentar?</h3>
        <p>Eu só posso indicar o que você pode ou prefere comer.</p>

        <div className="quiz-user-options">
          <Button btnClass={ hasRestrictions ? "selected-option-btn" : "option-btn" } clicked={() => onHandleClick(true)}>
            Possuo restrições alimentares
          </Button>
          <Button btnClass={ hasRestrictions === false ? "selected-option-btn" : "option-btn" } clicked={() => onHandleClick(false)}>
            Não possuo restrições alimentares
          </Button>
        </div>

        <div className="navigation-btn">
          <Button btnClass="back-btn" clicked={onBackBtn}>
            Voltar
          </Button>
          <Button btnClass="continue-btn" disabled={hasRestrictions === null} clicked={onContinueBtn}>
            Continuar
          </Button>
        </div>
      </Modal>
    </Aux>
  );
};

export default HasRestrictions;