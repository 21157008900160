const newApply = document.getElementById("new-apply");
const approved = document.getElementById("approved");
const onHold = document.getElementById("on-hold");
const banned = document.getElementById("banned");
const newApplyContent = document.getElementById("new-apply-content");
const approvedContent = document.getElementById("approved-content");
const onHoldContent = document.getElementById("on-hold-content");
const bannedContent = document.getElementById("banned-content");



if (newApply || approved){

  if (newApply){
    newApply.addEventListener("click", (event) => {
      event.currentTarget.classList.add("active");
      approved.classList.remove("active");
      onHold.classList.remove("active");
      banned.classList.remove("active");

      newApplyContent.classList.remove("hide");
      approvedContent.classList.add("hide");
      onHoldContent.classList.add("hide");
      bannedContent.classList.add("hide");
    });
  }

if (approved){
    approved.addEventListener("click", (event) => {
      event.currentTarget.classList.add("active");
      newApply.classList.remove("active");
      onHold.classList.remove("active");
      banned.classList.remove("active");

      newApplyContent.classList.add("hide");
      approvedContent.classList.remove("hide");
      onHoldContent.classList.add("hide");
      bannedContent.classList.add("hide");
    });
}

if (approved){
  onHold.addEventListener("click", (event) => {
    event.currentTarget.classList.add("active");
    newApply.classList.remove("active");
    approved.classList.remove("active");
    banned.classList.remove("active");

    newApplyContent.classList.add("hide");
    approvedContent.classList.add("hide");
    onHoldContent.classList.remove("hide");
    bannedContent.classList.add("hide");
  });
}

if (approved){
  banned.addEventListener("click", (event) => {
    event.currentTarget.classList.add("active");
    newApply.classList.remove("active");
    approved.classList.remove("active");
    onHold.classList.remove("active");

    newApplyContent.classList.add("hide");
    approvedContent.classList.add("hide");
    onHoldContent.classList.add("hide");
    bannedContent.classList.remove("hide");
  });
}

}




  export { newApply };
  export { approved };
  export { onHold };
  export { banned };




