import React, { useState, useContext } from 'react';

import { QuizContext } from '../../context/QuizContext';

import Aux from '../../hoc/Aux';
import Modal from '../UI/Modal/Modal';
import Button from '../UI/Button/button';

const Exercise = props => {
  const [showModal, setShowModal] = useState(true);
  const [state, setState] = useContext(QuizContext)

  const onHandleModalClosed = () => {
    setShowModal(false)
    props.history.push("/")
  };

  const onHandleClick = option => {
    setState(state => ({...state, physical_activity: option}))
  }

  const onContinueBtn = () => {
    props.history.push('/bowel');
  };

  const onBackBtn = () => {
    props.history.goBack();
  }

  return (
    <Aux>
      <Modal show={showModal} modalClosed={onHandleModalClosed}>
        <h3>Você pratica atividade física?</h3>
        <p>Correria no dia a dia todo mundo enfrenta, mas exercitar é fundamental.</p>
        <div className="quiz-user-options">
          <Button btnClass={ state.physical_activity === '0' ? "selected-option-btn" : "option-btn" } clicked={() => onHandleClick('0')}>
            Infelizmente, não...
          </Button>
          <Button btnClass={ state.physical_activity === '1-2' ? "selected-option-btn" : "option-btn" } clicked={() => onHandleClick('1-2')}>
            Me exercito de 1 a 2x por semana
          </Button>
          <Button btnClass={ state.physical_activity === '3-4' ? "selected-option-btn" : "option-btn" } clicked={() => onHandleClick('3-4')}>
            Me exercito de 3 a 4x por semana
          </Button>
          <Button btnClass={ state.physical_activity === '5-7' ? "selected-option-btn" : "option-btn" } clicked={() => onHandleClick('5-7')}>
            Me exercito de 5 a 7x por semana
          </Button>
        </div>  
        <div className="navigation-btn">
          <Button btnClass="back-btn" clicked={onBackBtn}>
            Voltar
          </Button>
          <Button btnClass="continue-btn" disabled={state.physical_activity === null} clicked={onContinueBtn}>
            Continuar
          </Button>
        </div>
      </Modal>
    </Aux>
  );
};

export default Exercise;