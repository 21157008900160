import React, { Component } from 'react';
import { Route, Switch, Link } from 'react-router-dom'

import { QuizProvider } from './context/QuizContext';

import Image from './components/UI/Image/Image';

import maia2 from '../../assets/images/maia2_transparent.png';

import GoalDownload from './components/GoalDownload/GoalDownload';
import Exercise from './components/Exercise/Exercise';
import Bowel from './components/Bowel/Bowel';
import BodyType from './components/BodyType/BodyType';
import HasRestrictions from './components/HasRestrictions/HasRestrictions';
import Restrictions from './components/Restrictions/Restrictions';
import Info from './components/Info/Info';
import Imc from './components/Imc/Imc';
import SignUp from './components/SignUp/SignUp';
import AppStore from './components/AppStore/AppStore';

class Download extends Component {
  render () {
    return (
      <QuizProvider>
        <div className="">
          <div className="action-group">
            <Link className="quiz-btn" to="/goal"> Faça o Quiz!</Link>
          </div>
        </div>
        <Switch>
          <Route path="/goal" component={GoalDownload} />
          <Route path="/exercise" component={Exercise} />
          <Route path="/bowel" component={Bowel} />
          <Route path="/bodytype" component={BodyType} />
          <Route path="/has_restrictions" component={HasRestrictions} />
          <Route path="/restrictions" component={Restrictions} />
          <Route path="/info" component={Info} />
          <Route path="/imc" component={Imc} />
          <Route path="/signup" component={SignUp} />
          <Route path="/appstore" component={AppStore} />
        </Switch>
      </QuizProvider>
    );
  }
}

export default Download;
