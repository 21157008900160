const filterCategoryDropdown = () => {
  const filterCategory = document.querySelectorAll(".filter-category")

  filterCategory.forEach((category) => {
    category.addEventListener("click", () => {
      const row = category.querySelector(".row");
      row.classList.toggle("hidden")
      const filterTitle = category.querySelector(".filter-title")
      if (filterTitle.firstElementChild.classList.contains("fa-caret-down")) {
        filterTitle.firstElementChild.classList.remove("fa-caret-down")
        filterTitle.firstElementChild.classList.add("fa-caret-up")
      } else {
        filterTitle.firstElementChild.classList.remove("fa-caret-up")
        filterTitle.firstElementChild.classList.add("fa-caret-down")
      }
    })
  })
}

export {filterCategoryDropdown};