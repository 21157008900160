import React, { useState, useContext } from 'react';

import { QuizContext } from '../../context/QuizContext';

import Aux from '../../hoc/Aux';
import Modal from '../UI/Modal/Modal';
import Button from '../UI/Button/button';
import Image from '../UI/Image/Image';

import body_rectangle from '../../../../assets/images/corpo_retangulo.png';
import body_pear from '../../../../assets/images/corpo_pera.png';
import body_hourglass from '../../../../assets/images/corpo_ampulheta.png';
import body_oval from '../../../../assets/images/corpo_oval.png';
import body_triangle from '../../../../assets/images/corpo_triangulo.png';

const BodyType = props => {
  const [state, setState] = useContext(QuizContext);

  const [showModal, setShowModal] = useState(true);
  const [bodyImage, setBodyImage] = useState([
    {name: "Retângulo", description: "Ombro, cintura e quadril da mesma largura", image: body_rectangle},
    {name: "Pêra", description: "Ombro e cintura mais estreitos, quadril largo", image: body_pear},
    {name: "Ampulheta", description: "Ombros e quadris da mesma largura,cintura fina", image: body_hourglass},
    {name: "Oval", description: "Cintura mais larga que ombros e quadril", image: body_oval},
    {name: "Triângulo", description: "Ombros largos, quadril e pernas mais finas", image: body_triangle}
  ])
  const [ bodyName, setBodyName] = useState(null)
  const [ bodyDescription, setBodyDescription] = useState(null)

  const onHandleModalClosed = () => {
    setShowModal(false)
    props.history.push("/")
  };


  const onContinueBtn = () => {
    props.history.push('/has_restrictions');
  };

  const onBackBtn = () => {
    props.history.goBack();
  }

  const onHandleMouseOver = (name, description) => {
    setBodyName(name);
    setBodyDescription(description);
  };

  const onHandleMouseLeave = () => {
    setBodyName(null);
    setBodyDescription(null);
  };

  const onSelectImage = name => {
    setState({...state, body_type: name})
  } 
  
  
  return (
    <Aux>

      <Modal show={showModal} modalClosed={onHandleModalClosed}>
        {bodyName === null ? <h3>Selecione seu biotipo</h3> : <h3>{bodyName}</h3>}
        {bodyDescription === null ? <p>Selecione uma das imagens abaixo.</p> : <p>{bodyDescription}</p>}
        
        <div className="quiz-user-options">
          {bodyImage.map(body =>  (
            <Image
              key={body.name}
              imgClass={ state.body_type === body.name ? "body-img-selected" : "body-img"}
              alt={body.name}
              source={body.image}
              mouseOver={() => onHandleMouseOver(body.name, body.description)} 
              mouseLeave={onHandleMouseLeave}
              clicked={() => onSelectImage(body.name)}/>
          ))}
        </div>
          
        <div className="navigation-btn">
          <Button btnClass="back-btn" clicked={onBackBtn}>
            Voltar
          </Button>
          <Button btnClass="continue-btn" disabled={state.body_type === null} clicked={onContinueBtn}>
            Continuar
          </Button>
        </div>
      </Modal>
    </Aux>
  );
};

export default BodyType