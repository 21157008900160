import React from 'react';
import ReactDOM from 'react-dom';
import { MemoryRouter } from 'react-router-dom';

import App from './App';
import Download from './Download';

const app = (
  <MemoryRouter>
    <App />
  </MemoryRouter>
);

const appDownload = (
  <MemoryRouter>
    <Download />
  </MemoryRouter>
);

const quiz = document.getElementById('quiz')
if (quiz){
  ReactDOM.render(app, quiz);
}

const download = document.getElementById('download')
if (download){
  ReactDOM.render(appDownload, download);
}
