import React, { useState } from 'react';

const QuizContext  = React.createContext([{}, () => {}]);

const QuizProvider = props => {
  const [state, setState]  = useState({
    goal: null,
    physical_activity: null,
    bowel_activity: null,
    body_type: null,
    restriction_ids: null,
    imc: null,
    referrer: null,
    authentication_token: null,
  });
  
  return (
    <QuizContext.Provider value={[state, setState]}>
      {props.children}

    </QuizContext.Provider>
  );
}

export {QuizContext, QuizProvider}