const ingredientRemove = () => {
  const removeBtn = document.querySelectorAll(".remove-ingredients-btn")

  removeBtn.forEach((btn) => {
    btn.addEventListener("click",() => {
      btn.parentElement.style.display = "none"
    })
  })
}

export { ingredientRemove }