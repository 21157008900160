const showInfoBtns = document.querySelectorAll(".see-info-btn");

  showInfoBtns.forEach((btn) => {
    btn.addEventListener("click", (event) => {
      showInfoClass = btn.attributes.class.value;
       const nutriId = showInfoClass.slice(-1);
       const newClass =  `.nutri-apply-extra-${nutriId}`;
       console.log(newClass);
      const extraInfo = document.querySelector(`${newClass}`);
      console.log(extraInfo);
      extraInfo.classList.remove("extra-info-hide");


      const hideInfoBtn = document.querySelector(`.btn-hide-${nutriId}`);

      console.log(hideInfoBtn)

      hideInfoBtn.addEventListener("click", (event) => {
        console.log(event.target)
        extraInfo.classList.add("extra-info-hide");
      });
    });
  })





