const hideRoutineBox = () => {
  $(".more-button").click(function(){
    var toggleBtn = document.querySelectorAll(".button-toggle-routine")
    toggleBtn.forEach(function(btn) {
      btn.innerText = "+"
    })
    $(".box-routine").slideUp();
  });
}

export {hideRoutineBox};