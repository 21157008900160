import React, { useState, useContext } from 'react';

import { QuizContext } from '../../context/QuizContext';

import Aux from '../../hoc/Aux';
import Modal from '../UI/Modal/Modal';
import Button from '../UI/Button/button';

const Objective = props => {
  const [showModal, setShowModal] = useState(true);
  const [state, setState] = useContext(QuizContext);

  const askClipboardPermission = () => {
    navigator.permissions.query({name: "clipboard-read"})
      .then(result => {
        if (result.state == "granted" || result.state == "prompt") {
          console.log('enable clipboard')
        }
      });
  }

  const copyToClipboard = (text) => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    // document.body.removeChild(el);
  };

  const getPromotionCode = () => {
    let member_code = new URL(location.href).searchParams.get('r');
    let input_copy = document.getElementById('promotion_code');
    input_copy.value = member_code;

    input_copy.focus();
    input_copy.select();

    console.log('member_code', member_code);
  
    askClipboardPermission();
    copyToClipboard(member_code);
  }

  const onHandleModalClosed = () => {
    setShowModal(false)
    props.history.goBack()
  };

  const onHandleClick = (option) => {
    setState(state => ({...state, goal: option}))
  };
  
  const onContinueBtn = () => {
    props.history.push('/exercise');
  };

  return(
    <Aux>
      <Modal
        show={showModal}
        modalClosed={onHandleModalClosed}>
        <h3>Qual é o seu objetivo?</h3>
        <p>Nossos cardápios são baseados em refeições saudáveis e balanceadas.</p>

        <div className="quiz-user-options">
          <Button btnClass={ state.goal === 'emagrecimento' ? "selected-option-btn" : "option-btn" }clicked={() => onHandleClick('emagrecimento')}>
            Perder peso com alimentação saudável
          </Button>
          <Button btnClass={ state.goal === 'equilibrada' ? "selected-option-btn" : "option-btn" }clicked={() => onHandleClick('equilibrada')}>
            Manter peso e melhorar alimentação
          </Button>
          <Button btnClass={ state.goal === 'esporte' ? "selected-option-btn" : "option-btn" }clicked={() => onHandleClick('esporte')}>
            Manter peso e melhorar o desempenho no esporte
          </Button>
          <Button btnClass={ state.goal === 'hipertrofia' ? "selected-option-btn" : "option-btn" }clicked={() => onHandleClick('hipertrofia')}>
            Ganhar massa muscular
          </Button>
        </div>

        <div className="lonely-btn">
          <Button btnClass="continue-btn" disabled={state.goal === null} clicked={onContinueBtn}>
            Continuar
          </Button>
        </div>
      </Modal>
    </Aux>
  );
}

export default Objective;