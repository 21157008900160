import React from 'react';

const Image = props => {
  return (
    <img 
      className={props.imgClass} 
      src={props.source} 
      alt={props.alt} 
      onClick={props.clicked}
      onMouseOver={props.mouseOver}
      onMouseLeave={props.mouseLeave}
    />
  );
}

export default Image;