import React from 'react';

const Button = props => {
  return (
    <button className={props.btnClass}
      onClick={props.clicked}
      disabled={props.disabled}>{props.children}</button>
  );
}

export default Button;