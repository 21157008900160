import React, {useState} from 'react';

import Aux from '../../hoc/Aux';
import Modal from '../UI/Modal/Modal';
import Image from '../UI/Image/Image';

import apple from '../../../../assets/images/apple-icon.png';
import android from '../../../../assets/images/google-play.png';
import maia from '../../../../assets/images/maia1_transparent.png'

const AppStore = props => {
  const [showModal, setShowModal] = useState(true);

  const onHandleModalClosed = () => {
    setShowModal(false)
    props.history.push("/")
  };

  const onClickStore = (store) => {
    switch(store) {
      case 'apple':
        window.open('https://apps.apple.com/br/app/fitlab/id1476332076', "_blank");
        break;
      case 'android': 
        window.open('https://play.google.com/store/apps/details?id=fitlab.app&hl=pt_BR','_blank');
        break;
    }
  }
  return(
    <Aux>
      <Modal show={showModal} modalClosed={onHandleModalClosed}>
        <h3>Baixe o app!</h3>
        <p>Venha conferir o cardápio que montamos para você!</p>
        <div className="app-stores"> 
          <Image imgClass="quiz-app-store"  source={apple} clicked={() => onClickStore('apple')}/>
          <Image imgClass="quiz-android-store" source={android} clicked={() => onClickStore('android')} />
        </div>
        <Image imgClass="quiz-maia-store" source={maia} />
      </Modal>
    </Aux>
  )
};

export default AppStore;