// import Chartkick from "chartkick";
// window.Chartkick = Chartkick;

import "bootstrap";
import "../component/check_box";

import { selectBox, selectAllProfiles, editCheckboxes } from '../component/check_box';
selectBox();
selectAllProfiles();
editCheckboxes();

import "jquery-bar-rating";
import "jquery-bar-rating/dist/themes/css-stars.css";

import { toggleHidden } from '../component/hide';

import { tabs } from '../component/tabs';

import { initStarRating } from '../component/init_star_rating';

initStarRating();


import { ingredientRemove } from '../component/ingredient_remove'
ingredientRemove();

import { sidedishRemove } from '../component/sidedish_remove'
sidedishRemove();

import { filterCategoryDropdown } from '../component/filter_dropdown'
filterCategoryDropdown();

import { loadDynamicBannerText } from '../component/banner';
loadDynamicBannerText();

import { hideRoutineBox } from '../component/hide_routine_box';
hideRoutineBox();

import '../quiz';


