import React, { useState, useContext } from 'react';

import { QuizContext } from '../../context/QuizContext';

import Aux from '../../hoc/Aux';
import Modal from '../UI/Modal/Modal';
import Button from '../UI/Button/button';

const Bowel = props => {
  const [showModal, setShowModal] = useState(true);
  const [state, setState] = useContext(QuizContext)

  const onHandleModalClosed = () => {
    setShowModal(false)
    props.history.push("/")
  };

  const onHandleClick = option => {
    setState(state => ({...state, bowel_activity: option}))
  }

  const onContinueBtn = () => {
    props.history.push('/bodytype');
  };

  const onBackBtn = () => {
    props.history.goBack();
  }

  return (
    <Aux>
      <Modal show={showModal} modalClosed={onHandleModalClosed}>
        <h3>Como funciona seu intestino?</h3>
        <p>Uma pergunta indiscreta, eu sei, mas acredite, ela é importante.</p>

        <div className="quiz-user-options">
          <Button btnClass={ state.bowel_activity === '1' ? "selected-option-btn" : "option-btn" } clicked={() => onHandleClick('1')}>
            Funciona diariamente
          </Button>
          <Button btnClass={ state.bowel_activity === '2' ? "selected-option-btn" : "option-btn" } clicked={() => onHandleClick('2')}>
            Funciona dia sim, dia não
          </Button>
          <Button btnClass={ state.bowel_activity === '3' ? "selected-option-btn" : "option-btn" } clicked={() => onHandleClick('3')}>
            Funciona a cada 3 dias ou mais
          </Button>
        </div>

        <div className="navigation-btn">
          <Button btnClass="back-btn" clicked={onBackBtn}>
            Voltar
          </Button>
          <Button btnClass="continue-btn" disabled={state.bowel_activity === null} clicked={onContinueBtn}>
            Continuar
          </Button>
        </div>
      </Modal>
    </Aux>
  );
};

export default Bowel;