import React, { useState, useContext } from 'react';

import { QuizContext } from '../../context/QuizContext';

import Aux from '../../hoc/Aux';
import Modal from '../UI/Modal/Modal';
import Button from '../UI/Button/button';
import Input from '../UI/Input/Input';

import { updateObject, checkValidity } from  '../../shared/utility';

const Info = props => {
  const [state, setState] = useContext(QuizContext);

  const [showModal, setShowModal] = useState(true);
  const [formIsValid, setFormIsValid] = useState(false);
  const [infoForm, setInfoForm] = useState({
    birth_date: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: 'dd/mm/aaaa'
      },
      label: 'Qual a sua data de nascimento?',
      value: '',
      validation: {
        required: true,
        maxLength: 10
      },
      valid: false,
      touched: false
    },
    height: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder:'1.70'
      },
      label: 'Qual a sua altura em metros?',
      value: '',
      validation: {
        required: true,
        maxLength: 4
      },
      valid: false,
      touched: false
    },
    weight: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: '70.3'
      },
      label: 'Quanto você pesa em kgs?',
      value: '',
      validation: {
        required: true,
        maxLength: 6
      },
      valid: false,
      touched: false
    },
    desired_weight: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder:'65'
      },
      label: 'Qual o seu peso pretendido em kgs?',
      value: '',
      validation: {
        required: true,
        maxLength: 6
      },
      valid: false,
      touched: false
    }
  })

  const onHandleModalClosed = () => {
    setShowModal(false)
    props.history.push("/")
  };

  const onContinueBtn = () => {
    const formData = {};
    for (let formElementIdentifier in infoForm) {
      formData[formElementIdentifier] = infoForm[formElementIdentifier].value
    }
    setState({...state, ...formData})
    
    props.history.push('/imc');
  };

  const onBackBtn = () => {
    props.history.goBack();
  }

  const inputChangedHandler = (event, inputIdentifier) => {
    let updatedFormElement = null
    let inputValue = event.target.value
    switch(inputIdentifier) {
      case 'birth_date':
        if (inputValue.match(/^\d{2}$/) !== null) {
          inputValue = inputValue + '/'
        } else if (inputValue.match(/^\d{2}\/\d{2}$/) !== null) {
          inputValue = inputValue + '/'
        }
        break;
      case 'height': 
        if (inputValue.length >= 2 && !(inputValue.includes('.'))) {
          inputValue = inputValue.replace(/(?!^)(?=(?:\d{1})+(?:\.|$))/gm, '.');
        }
        break;
      case 'weight':
        inputValue = inputValue.replace(',', '.');
        break;
      case 'desired_weight':
        inputValue = inputValue.replace(',', '.');
        break
    }
  
    updatedFormElement = updateObject(infoForm[inputIdentifier], {
      value: inputValue,
      valid: checkValidity(inputValue, infoForm[inputIdentifier].validation),
      touched: true
    });

    const updatedinfoForm = updateObject(infoForm, {
      [inputIdentifier]: updatedFormElement
    });

    let formIsValid = true;
    for (let inputIdentifiers in updatedinfoForm) {
      formIsValid = updatedinfoForm[inputIdentifiers].valid && formIsValid;
    }
    setInfoForm(updatedinfoForm);
    setFormIsValid(formIsValid);
  }

  const formElementsArray = [];

  for (let key in infoForm) {
    formElementsArray.push({ id: key, config: infoForm[key]});
  }

  let form = (
    <form>
      {formElementsArray.map(formElement => (
        <Input
              key={formElement.id}
              label={formElement.config.label}
              elementType={formElement.config.elementType}
              elementConfig={formElement.config.elementConfig}
              value={formElement.config.value}
              invalid={!formElement.config.valid}
              shouldValidate={formElement.config.validation}
              touched={formElement.config.touched}
              changed={(event) => inputChangedHandler(event, formElement.id)}
        />
      ))}
    </form>
  );
  
  return (
    <Aux>
      <Modal show={showModal} modalClosed={onHandleModalClosed}>
        <h3>Insira suas informações.</h3>
        <p>As informações são essenciais para calcular o seu IMC e cardápio ideal</p>
        {form}

        <div className="navigation-btn">
          <Button btnClass="back-btn" clicked={onBackBtn}>
            Voltar
          </Button>
          <Button btnClass="continue-btn" disabled={!formIsValid} clicked={onContinueBtn}>
            Continuar
          </Button>
        </div>
      </Modal>
    </Aux>
  );
};

export default Info;