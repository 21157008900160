
const selectBox = () => {
  const boxBtn = document.querySelectorAll(".category-choice")
  boxBtn.forEach((box) => {
    box.addEventListener("click", () => {
      box.classList.toggle("active");   
    })
  })
}

const selectAllProfiles = () => {
  const profileBox = document.querySelectorAll(".profile-box");
  const profileCheck = document.querySelectorAll(".profile-check");
  const allProfileBtn = document.getElementById("all-profiles");
  if(allProfileBtn) {
    allProfileBtn.addEventListener("click", () => {
      allProfileBtn.classList.toggle("active")
      profileBox.forEach((box) => {
        box.classList.toggle("active")
      })
      profileCheck.forEach((check) => {
        if (check.checked == false) {
          check.checked = true
        } else {
          check.checked = false
        }
      })
    })
  }
}

const editCheckboxes = () => {
  const checkBoxDiv= document.querySelectorAll(".checkbox-div")
  checkBoxDiv.forEach((div) => {
    const hiddenCheckBox = div.querySelector(".ingredient-edit-checkbox")
    const boxBtn = div.querySelector(".category-choice")
    if (hiddenCheckBox.checked == true) {
      boxBtn.classList.toggle("active"); 
    }
  })
}


export { selectBox, selectAllProfiles, editCheckboxes }
