import React, { useState, useContext } from 'react';
import firebase from "firebase";

import { QuizContext } from '../../context/QuizContext';

import Aux from '../../hoc/Aux';
import Modal from '../UI/Modal/Modal';
import Button from '../UI/Button/button';

const devFirebaseConfig = {
  apiKey: "AIzaSyD_FkWZgd6_TQmlxWNIKh9wY2mtbiIchao",
  authDomain: "fitlabtest-5045c.firebaseapp.com",
  databaseURL: "https://fitlabtest-5045c.firebaseio.com",
  projectId: "fitlabtest-5045c",
  storageBucket: "fitlabtest-5045c.appspot.com",
  messagingSenderId: "400552278049",
  appId: "1:400552278049:web:26abb96f29334af3124b60",
  measurementId: "G-3PNNYMQMWS"
};

const prodFirebaseConfig = {
  apiKey: "AIzaSyCOrDqc2itanQXq28DCD7lsDdZ32yWUUb0",
  authDomain: "fitlab-c30f2.firebaseapp.com",
  databaseURL: "https://fitlab-c30f2.firebaseio.com",
  projectId: "fitlab-c30f2",
  storageBucket: "fitlab-c30f2.appspot.com",
  messagingSenderId: "674018805171",
  appId: "1:674018805171:web:8b8fd4d949f2cab705f7c6",
  measurementId: "G-LTTG00K77S"
};

const Objective = props => {
  const [showModal, setShowModal] = useState(true);
  const [state, setState] = useContext(QuizContext);

  const initFirebase = () => {
    const firebaseConfig = "production" === "development" ? devFirebaseConfig : prodFirebaseConfig;

    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
  }

  const authGoogle = () => {
    var provider = new firebase.auth.GoogleAuthProvider();

    provider.addScope('https://www.googleapis.com/auth/contacts.readonly');

    firebase.auth()
            .signInWithPopup(provider)
            .then(function(result) {

      var token = result.credential.accessToken;
      var user = result.user;

      setState(state => ({
        ...state,
        authentication_token: token,
        provider: 'google',
        name: result.user.displayName,
        email: result.user.email
      }));

      props.history.push('/exercise');

      console.log("google success", result.user);
    }).catch(function(error) {
      console.log("google error ", error);

      alert(error.message);
    });
  }

  const authFacebook = () => {
    var provider = new firebase.auth.FacebookAuthProvider();

    provider.addScope('user_birthday');

    firebase.auth()
            .signInWithPopup(provider)
            .then(function(result) {

      var token = result.credential.accessToken;
      var user = result.user;

      setState(state => ({
        ...state,
        authentication_token: token,
        provider: 'facebook',
      }));

      props.history.push('/exercise');

      console.log("facebook success", result);
    }).catch(function(error) {
      console.log("facebook error ", error);

      alert(error.message);
    });
  }

  const createCustomToken = (token) => {
    firebase.auth().signInWithCustomToken(token)
      .then((user) => {
        console.log("signInWithCustomToken", user)
        // Signed in
        // ...
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log("error)", error)
      });

  }

  const askClipboardPermission = () => {
    navigator.permissions.query({name: "clipboard-read"})
      .then(result => {
        if (result.state == "granted" || result.state == "prompt") {
          console.log('enable clipboard')
        }
      });
  }

  const copyToClipboard = (text) => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    // document.body.removeChild(el);
  };

  const getPromotionCode = () => {
    let member_code = new URL(location.href).searchParams.get('r');
    let input_copy = document.getElementById('promotion_code');
    input_copy.value = member_code;

    input_copy.focus();
    input_copy.select();

    console.log('member_code', member_code);
  
    askClipboardPermission();
    copyToClipboard(member_code);
  }

  // initFirebase();
  // getPromotionCode();
  // setTimeout(() => authGoogle(), 800);

  const onHandleModalClosed = () => {
    setShowModal(false)
    props.history.goBack()
  };

  const onHandleClick = (option) => {
    setState(state => ({...state, goal: option}))
  };

  const onContinueBtnGoogle = () => {
    initFirebase();
    setTimeout(() => authGoogle(), 800);

    // props.history.push('/exercise');
  };

  const onContinueBtnFacebook = () => {
    initFirebase();
    setTimeout(() => authFacebook(), 800);

    // props.history.push('/exercise');
  };

  return(
    <Aux>
      <Modal
        show={showModal}
        modalClosed={onHandleModalClosed}>
        <h3>Qual é o seu objetivo?</h3>
        <p>Nossos cardápios são baseados em refeições saudáveis e balanceadas.</p>

        <div className="quiz-user-options">
          <Button btnClass={ state.goal === 'emagrecimento' ? "selected-option-btn" : "option-btn" }clicked={() => onHandleClick('emagrecimento')}>
            Perder peso com alimentação saudável
          </Button>
          <Button btnClass={ state.goal === 'equilibrada' ? "selected-option-btn" : "option-btn" }clicked={() => onHandleClick('equilibrada')}>
            Manter peso e melhorar alimentação
          </Button>
          <Button btnClass={ state.goal === 'esporte' ? "selected-option-btn" : "option-btn" }clicked={() => onHandleClick('esporte')}>
            Manter peso e melhorar o desempenho no esporte
          </Button>
          <Button btnClass={ state.goal === 'hipertrofia' ? "selected-option-btn" : "option-btn" }clicked={() => onHandleClick('hipertrofia')}>
            Ganhar massa muscular
          </Button>
        </div>

        <div className="group-social-btns">
          <Button btnClass="social-btn" disabled={state.goal === null} clicked={onContinueBtnGoogle}>
            Continuar com Google
          </Button>
          <Button btnClass="social-btn" disabled={state.goal === null} clicked={onContinueBtnFacebook}>
            Continuar com Facebook
          </Button>
        </div>
      </Modal>
    </Aux>
  );
}

export default Objective;