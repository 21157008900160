import React, {useState, useEffect, useContext} from 'react';

import axios from '../../axios/axios';

import { QuizContext } from '../../context/QuizContext';

import Aux from '../../hoc/Aux';
import Modal from '../UI/Modal/Modal';
import Button from '../UI/Button/button';

const Restrictions = props => {
  const [state, setState] = useContext(QuizContext)

  const [showModal, setShowModal] = useState(true);
  const [restrictionList, setRestrictionList] = useState([]);
  const [specialRestrictions, setSpecialRestrictions] = useState([])
  const [selectedSpecialRestriction, setSelectedSpecialRestriction] = useState()
  const [restrictions, setRestrictions] = useState([])
  

  useEffect(() => {
    fetchRestrictions()
  }, []);


  const fetchRestrictions = async () => {
    await axios.get('/restrictions')
      .then(response => { setRestrictionList( response.data) })
      .catch(error => { console.log(error) })
    setSpecialRestrictions(["Vegano", "Vegetariano", "Pescetariano"]);
  };

  const onSelectRestriction = id => {
    if (restrictions.includes(id)) {
      const index = restrictions.indexOf(id)
      const filteredRestrictions = [...restrictions].filter((r, i) => i !== index)
      setRestrictions(filteredRestrictions)
    } else {
      const concatRestrictions = [...restrictions].concat(id)
      setRestrictions(concatRestrictions)
    }
  };

  const onSpecialRestrictionHandler = specialRestriction => {
    const carne = restrictionList.find(r => r.name === "Carne");
    const ovos = restrictionList.find(r => r.name === "Ovos");
    const leite = restrictionList.find(r => r.name === "Leite/Lactose");
    const peixe = restrictionList.find(r => r.name === "Frutos do mar");

    switch(specialRestriction) {
      case 'Pescetariano':
        if (restrictions.includes(carne.id)) {
          const index = restrictions.indexOf(carne.id);
          const removeItem = [...restrictions].filter((r, i) => i !== index);
          setRestrictions(removeItem)
          setSelectedSpecialRestriction();
        } else {
          const addItem = [...restrictions].concat(carne.id);
          setRestrictions(addItem)
          setSelectedSpecialRestriction(specialRestriction)
        }
        break
      case 'Vegetariano':
        const vegetarianItems = [carne.id, peixe.id]
        if (vegetarianItems.every(el => restrictions.includes(el))) {
          const removeItems = [...restrictions].filter(el => !vegetarianItems.includes(el));
          setRestrictions(removeItems)
          setSelectedSpecialRestriction();
        } else {
          const concatItems = [...restrictions].concat(vegetarianItems)
          setRestrictions(concatItems)
          setSelectedSpecialRestriction(specialRestriction)
        }
        break;
      case 'Vegano':
        const veganItems = [carne.id, peixe.id, ovos.id, leite.id]
        if (veganItems.every(el => restrictions.includes(el))) {
          const removeItems = [...restrictions].filter(el => !veganItems.includes(el));
          setRestrictions(removeItems)
          setSelectedSpecialRestriction();
        } else {
          const concatItems = [...restrictions].concat(veganItems)
          setRestrictions(concatItems)
          setSelectedSpecialRestriction(specialRestriction)
        }
        break;
    }
  }
  
  const onHandleModalClosed = () => {
    setShowModal(false)
    props.history.push("/")
  };

  const onContinueBtn = () => {
    const uniqRestrictions = [...new Set(restrictions)]
    setState({...state, restriction_ids: JSON.stringify(uniqRestrictions)})
    props.history.push('/info');
  };
  
  const onBackBtn = () => {
    props.history.goBack();
  }

  return(
    <Aux>
      <Modal show={showModal} modalClosed={onHandleModalClosed}>
        <h3>Quais são suas restrições?</h3>
        <p>Selecione uma ou mais restrições abaixo.</p>

        <div className="quiz-user-options">
          {specialRestrictions.map(spRestriction => (
            <Button 
              btnClass={ selectedSpecialRestriction === spRestriction ? 'selected-special-restriction-btn' : 'special-restriction-btn'}
              key={ spRestriction }
              clicked={() => onSpecialRestrictionHandler(spRestriction)}>{spRestriction}</Button>
          ))}
          <br/>
          {restrictionList.map(restriction => (
            <Button 
              btnClass={ restrictions.includes(restriction.id)  ? "selected-restriction-btn" : "restriction-btn" }
              key={restriction.name}
              clicked={() => onSelectRestriction(restriction.id)}>Sem {restriction.name}</Button>
          ))}
        </div>

        <div className="navigation-btn">
          <Button btnClass="back-btn" clicked={onBackBtn}>
            Voltar
          </Button>
          <Button btnClass="continue-btn" disabled={restrictions.length === 0} clicked={onContinueBtn}>
            Continuar
          </Button>
        </div>
      </Modal>
    </Aux>
  );
};

export default Restrictions;