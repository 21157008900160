
import React from 'react';

const input = (props) => {
  let inputElement = null;
  const inputClasses = ['quiz-input-element'];

  if (props.invalid && props.shouldValidate && props.touched) {
    inputClasses.push('quizInvalid')
  }

  switch (props.elementType) {
    case ('input'):
      inputElement = <input className={inputClasses.join(' ')} {...props.elementConfig} value={props.value} onChange={props.changed}/>;
      break;
    case ('select'):
      inputElement = (
        <select 
          className={inputClasses.join(' ')} 
          value={props.value} 
          onChange={props.changed}>
          {props.elementConfig.options.map(option => (
            <option key={option.value} value={option.value}>
              {option.displayValue}
            </option>
          ))}
        </select>);
      break;
    default:
      inputElement = <input className={inputClasses.join(' ')} {...props.elementConfig} value={props.value} onChange={props.changed}/>;
  }


  return (
    <div className='quiz-input'>
      <label className='quiz-label'>{props.label}</label>
      {inputElement}
    </div>
  );
};

export default input; 