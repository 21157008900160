import React, { useState, useContext } from 'react';

import { QuizContext } from '../../context/QuizContext';

import Aux from '../../hoc/Aux';
import Modal from '../UI/Modal/Modal';
import Button from '../UI/Button/button';
import Input from '../UI/Input/Input';

import { updateObject, checkValidity } from  '../../shared/utility';

import axios from '../../axios/axios';

const SignUp = props => {
  const [state, setState] = useContext(QuizContext);

  const [showModal, setShowModal] = useState(true);
  const [formIsValid, setFormIsValid] = useState(false);
  const [signUpForm, setSignUpForm] = useState({
    name: {
      elementType: 'input',
      elementConfig: {
        type: 'text'
      },
      label: 'Nome',
      value: state.name,
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    gender: {
      elementType: 'select',
      elementConfig: {
        options: [
          {value: 'masculino', displayValue: 'Masculino'},
          {value: 'feminino', displayValue: 'Feminino'},
          {value: 'outro', displayValue: 'Outro'}
        ]
      },
      label: 'Sexo',
      value: 'outro',
      validation: {},
      valid: false,
      touched: false
    },
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'text'
      },
      label: 'Email',
      value: state.email,
      validation: {
        required: true,
        isEmail: true
      },
      valid: false,
      touched: false
    },
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password'
      },
      label: 'Criar Senha',
      value: '',
      validation: {
        required: true,
        minLength: 6
      },
      valid: false,
      touched: false
    },
    password_confirmation: {
      elementType: 'input',
      elementConfig: {
        type: 'password'
      },
      label: 'Confirme sua senha',
      value: '',
      validation: {
        required: true
        
      },
      valid: false,
      touched: false
    }
  })

  const onHandleModalClosed = () => {
    setShowModal(false)
    props.history.push("/")
  };

  const onContinueBtn = () => {
    const formData = {};
    for (let formElementIdentifier in signUpForm) {
      formData[formElementIdentifier] = signUpForm[formElementIdentifier].value
    }

    setState({...state, ...formData})
    const finalQuiz = Object.assign(state, {...formData})
    userSignUp(finalQuiz)

    props.history.push('/appstore')
  };

  // ### @quiz params
  // 
  // birth_date: "03/12/1988"
  // body_type: "Pêra"
  // bowel_activity: "2"
  // desired_weight: "82"
  // email: "fabian.pow@gmail.com"
  // gender: "masculino"
  // goal: "equilibrada"
  // height: "1.80"
  // imc: "25.93"
  // name: "fabian"
  // password: "03121988"
  // password_confirmation: "03121988"
  // physical_activity: "1-2"
  // restriction_ids: "[19]"
  // weight: "84"
  const userSignUp = async (quiz) => {
    let ref = new URL(location.href).searchParams.get('ref');
    let member_code = new URL(location.href).searchParams.get('r');

    await axios.post(`/users?ref=${ref}&r=${member_code}`, quiz)
      .then(response => console.log(response))
      .catch(error => console.log(error))
  }

  const onBackBtn = () => {
    props.history.goBack();
  }

  const inputChangedHandler = (event, inputIdentifier) => {
    const updatedFormElement = updateObject(signUpForm[inputIdentifier], {
      value: event.target.value,
      valid: checkValidity(event.target.value, signUpForm[inputIdentifier].validation),
      touched: true
    });

    const updatedSignUpForm = updateObject(signUpForm, {
      [inputIdentifier]: updatedFormElement
    });

    let formIsValid = true;
    for (let inputIdentifiers in updatedSignUpForm) {
      formIsValid = updatedSignUpForm[inputIdentifiers].valid && formIsValid;
    }
    setSignUpForm(updatedSignUpForm);
    setFormIsValid(formIsValid);
  }

  const formElementsArray = [];

  for (let key in signUpForm) {
    formElementsArray.push({ id: key, config: signUpForm[key]});
  }

  let form = (
    <form>
      {formElementsArray.map(formElement => (
        <Input
              key={formElement.id}
              label={formElement.config.label}
              elementType={formElement.config.elementType}
              elementConfig={formElement.config.elementConfig}
              value={formElement.config.value}
              invalid={!formElement.config.valid}
              shouldValidate={formElement.config.validation}
              touched={formElement.config.touched}
              changed={(event) => inputChangedHandler(event, formElement.id)}
        />
      ))}
    </form>
  );
  
  return (
    <Aux>
      <Modal show={showModal} modalClosed={onHandleModalClosed}>
        <h3>Criar Conta</h3>
        <p>Crie uma conta grátis e veja o cardápio que preparei para você!</p>
        {form}
        <div className="navigation-btn">
          <Button btnClass="back-btn" clicked={onBackBtn}>
            Voltar
          </Button>
          <Button btnClass="continue-btn" disabled={!formIsValid} clicked={onContinueBtn}>
            Continuar
          </Button>
        </div>
      </Modal>
    </Aux>
  );
};

export default SignUp;