import Typed from 'typed.js';

const loadDynamicBannerText = () => {

  if (document.getElementById('banner-typed-text')){

    new Typed('#banner-typed-text', {
      strings: ["Sem restrições", "Sem sofrimento", "Sem neuras", "Baixe o App"],
      typeSpeed: 70,
      loop: true,
      smartBackspace: true,
      showCursor: false
    });
  }
}

export { loadDynamicBannerText };
