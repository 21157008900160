import React, {useState, useEffect, useContext} from 'react';

import { QuizContext } from '../../context/QuizContext';

import Aux from '../../hoc/Aux';
import Modal from '../UI/Modal/Modal';
import Button from '../UI/Button/button';
import Image from '../UI/Image/Image';

import imc1 from '../../../../assets/images/imc1.png';
import imc2 from '../../../../assets/images/imc2.png';
import imc3 from '../../../../assets/images/imc3.png';

const Imc = props => {
  const [state, setState] = useContext(QuizContext);

  const [showModal, setShowModal] = useState(true);
  const [image, setImage] = useState('');
  const [type, setType] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    const height = +state.height;
    const weight = +state.weight;
    const imc = (weight / (height ** 2)).toFixed(2)
    setState({...state, imc: imc});
    imcInfo(imc)
  }, []);

  const imcInfo = imc => {
    if (imc >= 25) {
      setType('Acima de peso');
      setImage(imc3);
      setDescription('Seu índice de massa corpórea está um pouco acima do ideal. Mas não se preocupe, vamos te ajudar a ajustar a dieta e a chegar ao seu objetivo!')
    } else if (imc <= 18.5) {
      setType('Abaixo do peso');
      setImage(imc2);
      setDescription('Hum... Seu índice de massa corpórea está um pouco abaixo do ideal. Vamos ajustar sua dieta pra você ganhar massa magra e chegar ao seu objetivo!')
    } else {
      setType('IMC Ideal');
      setImage(imc1);
      setDescription('Muito bom! Seu índice de massa corpórea está dentro do padrão normal de saúde! Com pequenos ajustes na dieta você chegará aos seus objetivos rapidinho!')
    }
  };
  
  const onHandleModalClosed = () => {
    setShowModal(false)
    props.history.push("/")
  };

  const onContinueBtn = () => {
    props.history.push('/signup');
    
  };

  const onBackBtn = () => {
    props.history.goBack();
  }

  return (
    <Aux>
      <Modal show={showModal} modalClosed={onHandleModalClosed}>
        <h3>Seu IMC</h3>
        <div className="quiz-user-options">
          <Image imgClass="quiz-imc-img" source={image} />
          <h1 className="quiz-imc-number">{state.imc}</h1>
          <h3 className="quiz-imc-type">{type}</h3>
          <p className="quiz-imc-description">{description}</p>
        </div>
          
        <div className="navigation-btn">
          <Button btnClass="back-btn" clicked={onBackBtn}>
            Voltar
          </Button>
          <Button btnClass="continue-btn" disabled={state.body_type === null} clicked={onContinueBtn}>
            Continuar
          </Button>
        </div>
      </Modal>
    </Aux>
  );
};

export default Imc;